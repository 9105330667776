@import url('https://fonts.googleapis.com/css2?family=Bitter:wght@600&display=swap');

.navbar-custom {
  background-color: darkred;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(227, 199, 166, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='3' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}

.footer-custom {
background-color: darkred;
}

.footer-custom h3, h5 {
  color: wheat;
  }

  .footer-custom .email-btn {
    width: 100%;
    background-color: aliceblue;
  }
  a.nav-link {
    color: white !important;
    font-family: 'Bitter', serif;
  }
a.nav-link:hover {
  color: wheat !important;
}

div #sect1 {
  height: 450px;
  background-image: url(https://images.pexels.com/photos/7148675/pexels-photo-7148675.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1);
  background-size: cover;
  background-position: center;
}

div #sect1 .sect1-overlay {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,.6);
}

div #sect1 .sect1-overlay .get-started {
  border-radius: 30px;
  background-color: #8B0000;
}

div #sect1 .sect1-overlay #outlined {
  border-radius: 30px;
  border: 2px solid #8B0000;
}
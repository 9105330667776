.beer-page .card-custom {
    max-width: 450px;
    max-height: 250px;
}

.beer-page .dropdown-menu {
    border-color: darkred;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: wheat;
}

.beer-page .dropdown-item:hover {
    background-color: darkred;
    color: wheat;

}

.beer-page .dropdown-item {
    background-color: wheat;
}
.beer-page .brew-card {
    max-width: 450px;
    max-height: 250px;
    padding-bottom: 50%;
    border-top: 5px solid darkred;
}

.beer-page .rapt-card {
    max-width: 160px;
    max-height: 160px;
    border-top: 1px solid darkred;
}

.beer-page .card {
    overflow: hidden;
    box-shadow: 0 0 20px rgb(0 0 0 / 50%);
    background-color: wheat;
    border-color: darkred;
}

.beer-page .card-title {
    color: darkred;
    width: 100%;
    border-bottom: 1px solid darkred;
}

/* 2.2 CARD OVERLAY CSS*/
.beer-page .embed-responsive-item {
    box-shadow: 0 0 30px rgb(0 0 0 / 50%);
    border-color: darkred;
}

.beer-page .card-body {
    font-size: small;
}

.form-control {
    border: 4px solid #ffe283 !important;
}

.invalid-feedback {
    color: #ffe283 !important;
}

.beer-page .modal-content {
    background-color: wheat;
    color: darkred;
}

.beer-page .modal-content {
    background-color: wheat;
    border-color: darkred;
}

.beer-page .modal-header {
    border-bottom-color: darkred;
}

.beer-page .modal-footer {
    border-top-color: darkred;
}